.select-container[data-v-a4426e2a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 5px;
  border: 1px solid #ccc;
  line-height: 30px;
}
.select-container .select-title[data-v-a4426e2a] {
  width: 100px;
  text-align: right;
  padding-left: 12px;
  margin-right: 10px;
}
.select-container .tag-container[data-v-a4426e2a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  overflow: hidden;
}
.select-container .tag-container .el-tag[data-v-a4426e2a] {
  height: 32px;
  line-height: 32px;
  margin-right: 12px;
  margin-bottom: 5px;
}
.select-container .tag-container .el-tag .el-icon-close[data-v-a4426e2a] {
  font-size: 16px;
}
.select-container .arrow-icon[data-v-a4426e2a] {
  cursor: pointer;
  font-size: 14px;
  color: #66b1ff;
}
.select-container .arrow-icon span[data-v-a4426e2a] {
  margin-left: 5px;
}
.item-container[data-v-a4426e2a] {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}
.item-container .item-content[data-v-a4426e2a] {
  line-height: 30px;
  padding: 5px 12px 0 12px;
  border-bottom: 1px solid #ccc;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.item-container .item-content .item-tag[data-v-a4426e2a] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.item-container .item-content .item-tag .el-tag[data-v-a4426e2a] {
  height: 32px;
  line-height: 32px;
  margin-right: 12px;
  margin-bottom: 5px;
  cursor: pointer;
  width: 100px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item-container .item-content .item-title[data-v-a4426e2a] {
  width: 100px;
  text-align: right;
  display: inline-block;
  margin-right: 10px;
}
.item-container .item-content .arrow[data-v-a4426e2a] {
  width: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
}
.item-container .item-content .arrow .arrow-icon[data-v-a4426e2a] {
  font-size: 14px;
  color: #66b1ff;
}
.item-container .item-content .arrow span[data-v-a4426e2a] {
  font-size: 14px;
  margin-left: 5px;
  color: #66b1ff;
}
.allBigBox[data-v-a4426e2a] {
  height: 129px;
  overflow: hidden;
}
.openAll[data-v-a4426e2a] {
  width: calc(100% - 2px);
  text-align: center;
  border: 1px solid #ccc;
  border-top: none;
  height: 43px;
  line-height: 43px;
  cursor: pointer;
}
.item-name[data-v-a4426e2a] {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #ccc;
}